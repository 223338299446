<template>
  <div>
    <v-card>
      <v-card-title>Listado de Wellboats</v-card-title>
      <v-card-text>
        <botonera 
          :botones="botones" 
          alinear="right"
          @crear="dialog = !dialog"
          @cargar="upload = !upload"
          @eliminar="deleteAll"
        ></botonera>
      </v-card-text>
    </v-card>
    <datatable 
      :cabeceras="wellboatsListHeaders" 
      :items="wellboatsListBody" 
      titulo="Listado de Wellboats" 
      label="Escriba Para buscar Wellboats" 
      icono="list" 
      color_icono="azul"
      :tachar="true"
      :acciones="['editar','eliminar','mostrar','ocultar']"
      @editar="editWellboat($event)"
      @eliminar="deleteWellboat($event)"
      @mostrar="mostrar($event)"
      @ocultar="ocultar($event)">
    </datatable>
    <!-- Modal Nueva Wellboat -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col
              class="align-center justify-space-between"
              cols="4" 
            >
              <v-text-field
                prepend-icon="mdi-navigation"
                label="Nombre"
                placeholder="Nombre"
                v-model="wellboat.nombre"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4" 
            >
              <v-text-field
                prepend-icon="mdi-laptop"
                label="Capacidad Total (m3)"
                placeholder="Capacidad Total (m3)"
                v-model="wellboat.m3"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4" 
            >
              <v-text-field
                prepend-icon="mdi-format-size"
                label="Sistema"
                placeholder="Sistema"
                v-model="wellboat.sistema"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4" 
            >
              <v-text-field
                prepend-icon="mdi-speedometer"
                label="Nudos Vacio"
                placeholder="Nudos Vacio"
                v-model="wellboat.nudos_vacio"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4" 
            >
              <v-text-field
                prepend-icon="mdi-speedometer"
                label="Nudos Cargado"
                placeholder="Nudos Cargado"
                v-model="wellboat.nudos_cargado"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4" 
            >
              <v-text-field
                prepend-icon="mdi-timelapse"
                label="Tiempo de carga"
                placeholder="Tiempo de carga"
                v-model="wellboat.tiempo_carga"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" >
              <v-select 
                prepend-icon="mdi-weight"
                v-model="wellboat.tipo_descarga"
                :items="tipoDescargas"
                item-text="nombre"
                item-value="id"
                label="Tipo de Descarga"
                color="blue darken-3"
                persistent-hint
                return-object
              >
              </v-select>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4" 
            >
              <v-text-field
                prepend-icon="mdi-weight-kilogram"
                label="Capacidad bodegas Salar (Ton)"
                placeholder="Capacidad bodegas Salar (Ton)"
                v-model="wellboat.toneladas"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4" 
            >
              <v-text-field
                prepend-icon="mdi-weight-kilogram"
                label="Capacidad bodegas Coho/Trucha (Ton)"
                placeholder="Capacidad bodegas Coho (Ton)"
                v-model="wellboat.toneladas_coho"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4" 
            >
              <v-text-field
                prepend-icon="mdi-weight-kilogram"
                label="Folio Inicial"
                placeholder="Folio Inicial"
                v-model="wellboat.min_folio"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4" 
            >
              <v-select 
                prepend-icon="mdi-weight"
                v-model="wellboat.centro_costos_id"
                :items="centro_costos"
                item-text="nombre"
                item-value="id"
                label="Centro de Costo"
                color="blue darken-3"
                persistent-hint
              >
              </v-select>
            </v-col>
            <v-col cols="4" v-for="(item, i) in wellboat.bodegas" :key="item.id">
              <v-text-field
                prepend-icon="mdi-weight-kilogram"
                :label="'M3 ' + item.bodega.nombre"
                placeholder="Folio Inicial"
                v-model="wellboat.bodegas[i].m3"
                v-if="(wellboat.id > 0)"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4" 
              >
              <v-text-field
                prepend-icon="mdi mdi-identifier"
                label="ID Sistema Turnos"
                placeholder="ID Sistema Turnos"
                v-model="wellboat.wellboats_id_sistema_turnos"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="closeModal"
          >Cerrar</v-btn>
          <v-btn
            color="primary"
            @click="saveWellboat"
          >Grabar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <!-- Modal upload -->
    <v-dialog
      v-model="upload"
      width="600px"
    >
      <v-card>
        <v-card-title class="green darken-1">
          Subir listado de wellboats desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="upload = false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="uploadWellboats"
          >Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>
<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    data: () => ({
      title: 'Nuevo Wellboat',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      success: null,
      search: null,
      file: null,
      dialog: false,
      upload: false,
      tipoDescargas: [],
      wellboatIndex: -1,
      bodegas: [],
      centro_costos: [],
      wellboat: {
        id: 0,
        nombre: '',
        m3: '0',
        sistema: '',
        nudos_vacio: '',
        nudos_cargado: '',
        tiempo_carga: '',
        tipo_descarga: {},
        toneladas: '',
        toneladas_coho: '',
        bodegas: [],
        centro_costos_id: 0,
        wellboats_id_sistema_turnos: 0,
      },
      wellboatsListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Nombre',
          align: 'center',
          sortable: true,
          value: 'nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Capacidad de Bodega(m3)',
          align: 'center',
          sortable: true,
          value: 'm3',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Sistema',
          align: 'center',
          sortable: true,
          value: 'sistema',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Centro Costo',
          align: 'center',
          sortable: true,
          value: 'centro_costo.nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'M3 Por Bodega',
          align: 'center',
          sortable: true,
          width: '200px',
          value: 'm3_bodegas',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Nudos Vacio',
          align: 'center',
          sortable: true,
          value: 'nudos_vacio',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Nudos Cargado',
          align: 'center',
          sortable: true,
          value: 'nudos_cargado',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Tiempo Carga',
          align: 'center',
          sortable: true,
          value: 'tiempo_carga',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Tipo Descarga',
          align: 'center',
          sortable: true,
          value: 'tipo_descarga.nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Capacidad Bodegas Salar (ton)',
          align: 'center',
          sortable: true,
          value: 'toneladas',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Capacidad Bodegas Coho/Trucha (ton)',
          align: 'center',
          sortable: true,
          value: 'toneladas_coho',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Folio Inicial',
          align: 'center',
          sortable: true,
          value: 'min_folio',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          width: '150px',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      wellboatsListBody: [],
      botones:[
        {boton:'crear', tooltip:'Nuevo Wellboat'},
        {boton:'cargar', tooltip:'Carga Masiva'},
        {boton:'eliminar', tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadBodegas();
      this.loadCentroCostos();
      this.loadTipoDescargas();
      this.loadWellboats();
    },
    methods:{
      async loadBodegas(){
          let url = `${this.base_url}bodegas`;
          await this.axios.get(url, this.headers).then((response)=>{
          this.bodegas = response.data;
          }).catch((error)=>{
              console.log(error);
          });
      },
      async loadCentroCostos(){
          let url = `${this.base_url}centros_costos`;
          await this.axios.get(url, this.headers).then((response)=>{
          this.centro_costos = response.data;
          }).catch((error)=>{
              console.log(error);
          });
      },
      async loadTipoDescargas(){
          let url = `${this.base_url}tipo_descargas`;
          await this.axios.get(url, this.headers).then((response)=>{
          this.tipoDescargas = response.data;
          }).catch((error)=>{
              console.log(error);
          });
      },
      async loadWellboats(){
        this.mostrarLoading('Cargando listado de wellboats...')
        let url = `${this.base_url}wellboats`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.wellboatsListBody = response.data;
          this.ocultarLoading()
        }).catch((error)=>{
            console.log(error);
            this.ocultarLoading()
        });
      },
      async saveWellboat(){
        // validation
        this.errors = [];
        if(!this.wellboat.nombre){
          this.errors.push("Debe indicar nombre");
        }else if(this.wellboat.nombre.length > 30){
          this.errors.push("Tamaño máximo nombre: 30 caracteres");
        }
        if(!this.wellboat.m3 || isNaN(this.wellboat.m3) || this.wellboat.m3 <= 0){
          this.errors.push("Debe indicar capacidad");
        }
        if(!this.wellboat.sistema){
          this.errors.push("Debe indicar sistema");
        }else if(this.wellboat.sistema.length > 20){
          this.errors.push("Tamaño máximo sistema: 20 caracteres");
        }
        if(!this.wellboat.nudos_vacio || isNaN(this.wellboat.nudos_vacio) || this.wellboat.nudos_vacio <= 0){
          this.errors.push("Debe indicar nudos vacio");
        }
        if(!this.wellboat.nudos_cargado || isNaN(this.wellboat.nudos_cargado) || this.wellboat.nudos_cargado <= 0){
          this.errors.push("Debe indicar nudos cargado");
        }
        if(!this.wellboat.tiempo_carga){
          this.errors.push("Debe indicar tiempo de carga");
        }
        if(!this.wellboat.tipo_descarga.id){
          this.errors.push("Debe indicar tipo de descarga");
        }
        if(!this.wellboat.toneladas || isNaN(this.wellboat.toneladas) || this.wellboat.toneladas <= 0){
          this.errors.push("Debe indicar toneladas");
        }
        if(!this.wellboat.toneladas_coho || isNaN(this.wellboat.toneladas_coho) || this.wellboat.toneladas_coho <= 0){
          this.errors.push("Debe indicar toneladas Coho/Trucha");
        }
        if(!this.wellboat.wellboats_id_sistema_turnos || isNaN(this.wellboat.wellboats_id_sistema_turnos) || this.wellboat.wellboats_id_sistema_turnos <= 0){
          this.errors.push("Debe indicar ID Sistema Turnos");
        }
        if(this.errors.length > 0){
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let url = `${this.base_url}wellboats`;
        let wellboatData = {
          'nombre': this.wellboat.nombre,
          'm3': this.wellboat.m3,
          'sistema': this.wellboat.sistema,
          'nudos_vacio': this.wellboat.nudos_vacio,
          'nudos_cargado': this.wellboat.nudos_cargado,
          'tiempo_carga': this.wellboat.tiempo_carga,
          'tipo_descargas_id': this.wellboat.tipo_descarga.id,
          'toneladas': this.wellboat.toneladas,
          'toneladas_coho': this.wellboat.toneladas_coho,
          'min_folio': this.wellboat.min_folio,
          'centro_costos_id': this.wellboat.centro_costos_id,
          'wellboats_id_sistema_turnos': this.wellboat.wellboats_id_sistema_turnos,
        };
        if(this.wellboat.id > 0){
          wellboatData.bodegas = this.wellboat.bodegas;
          url = url + "/" + this.wellboat.id;
          await this.axios.put(url, wellboatData, this.headers).then((response)=>{
            let index = this.wellboatsListBody.map(wellboat => {
              return wellboat.id;
            }).indexOf(this.wellboat.id);
            this.wellboatsListBody.splice(index, 1, response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            this.validation_message = error;
            this.showSnackBar(true);
          });
        }else{
          await this.axios.post(url, wellboatData, this.headers).then((response)=>{
            this.wellboatsListBody.push(response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            this.errors = error.response.data;
            this.validation_message = this.errors.join();
            this.showSnackBar(true);
          });
        }
        this.cleanForm();
      },
      editWellboat(item) {
        this.wellboatIndex = this.wellboatsListBody.indexOf(item);
        this.wellboat = Object.assign({}, item);
        this.dialog = true;
        this.title = "Editar Wellboat";
      },
      async deleteWellboat(item) {
        this.$confirm('Desea borrar este wellboat?').then(res => {
          if(res){
            let url = `${this.base_url}wellboats/${item.id}`;
            this.axios.delete(url, this.headers).then((response)=>{
              console.log(response);
              this.loadWellboats();
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      closeModal(){
        this.cleanForm();
        this.dialog = false;
        this.error = null;
        this.title = "Nuevo Wellboat";
      },
      cleanForm(){
        this.wellboat = {
          id: 0,
          nombre: '',
          m3: 0,
          sistema: '',
          nudos_vacio: '',
          nudos_cargado: '',
          tiempo_carga: '',
          tipo_descarga: {},
          toneladas: '',
          toneladas_coho: '',
          wellboats_id_sistema_turnos: '',
        };
        this.dialog = false;
      },
      async uploadWellboats(){
        this.headers.headers['Content-Type'] = "multipart/form-data";
        let url = `${this.base_url}uploads/wellboats`;
        let formData = new FormData();
        formData.append("excel", this.file);
        let response = null;
        try{
          response = await this.axios.post(url, formData, this.headers);
          this.file = null;
          this.upload = false;
          this.loadWellboats();
          this.showSnackBar(false);
        }catch(error){
          response = error.response;
          let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
          this.showSnackBar(true);
        }
      },
      async deleteAll(){
        this.$confirm('Desea borrar todos los datos?').then(res => {
          if(res){
            let url = `${this.base_url}truncar/wellboats`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.loadWellboats();
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async ocultar(item) {
        this.$confirm('Desea ocultar este wellboat?').then(res => {
          if(res){
            let url = `${this.base_url}wellboats/ocultar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadWellboats();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async mostrar(item) {
        this.$confirm('Desea mostrar este wellboat?').then(res => {
          if(res){
            let url = `${this.base_url}wellboats/mostrar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadWellboats();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers']),
      
    },
    components:{
      
    }
  }
</script>